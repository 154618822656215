var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-contents"},[_c('v-row',{staticClass:"flex-grow-0 mb-3",class:{'nowrap': _vm.$vuetify.breakpoint.mdAndUp, 'px-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"align-center":"","wrap":_vm.$vuetify.breakpoint.xsOnly,"no-gutters":"","justify-space-between":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.emitFetch}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v(_vm._s(_vm.ICONS.REFRESH))]),_vm._v(" "+_vm._s(_vm.$t('buttons.refresh_list'))+" ")],1)],1),_c('v-col',[_c('v-layout',{attrs:{"justify-end":""}},[(_vm.canAddTrack)?_c('v-btn',{attrs:{"color":"secondary","data-test":"addNewEmployeeBtn"},on:{"click":function($event){return _vm.openTrackConfiguration()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.ICONS.ADD))]),_vm._v(" "+_vm._s(_vm.$t('tracks.add_title'))+" ")],1):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',[_c('p',{staticClass:"mb-0 text-left text--xs"},[_vm._v(" "+_vm._s(_vm.$t('labels.all_records'))+": "),_c('strong',[_vm._v(_vm._s(_vm.pagination.amountAll || 0))])])])],1),_c('v-data-table',{staticClass:"elevation-2 table-wrapper",attrs:{"headers":_vm.headers,"hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"disable-sort":"","loading":_vm.loader,"items":_vm.tracks,"disable-pagination":"","hide-default-footer":"","show-select":"","height":"100%"},on:{"dblclick:row":_vm.showTrackDetails},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(headerItem,index){return _c('th',{key:index},[(headerItem.value === 'data-table-select')?_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":props.someItems && !props.everyItem,"color":"purple"},on:{"input":on['toggle-select-all']},model:{value:(props.everyItem),callback:function ($$v) {_vm.$set(props, "everyItem", $$v)},expression:"props.everyItem"}}):_c('SortableHeader',{attrs:{"header-item":headerItem},on:{"sort":_vm.sort}})],1)}),0)])]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showDetailsDialog(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.DETAILS,"data-test":"showDetailsBtn","tooltip":_vm.$t('actions.view')},on:{"click":function($event){return _vm.showDetailsDialog(item)}}}),(_vm.canEditTrack)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":"editTrackBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.openTrackConfiguration(item)}}}):_vm._e(),(_vm.canDeleteTrack)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteTrack}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showGroupActions)?_c('div',{staticClass:"flex-grow-0 mt-6"},[(_vm.canDeleteTrack)?_c('ButtonWithConfirmation',{attrs:{"item-id":_vm.selected,"callback":_vm.deleteManyTracks}},[[_c('v-btn',{staticClass:"mr-3",attrs:{"text":""}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v(_vm._s(_vm.ICONS.DELETE))]),_vm._v(" "+_vm._s(_vm.$t('buttons.remove_selected'))+" ")],1)]],2):_vm._e()],1):_vm._e(),(_vm.activeTrack)?_c('TrackDetails',{attrs:{"dialog":_vm.showDetails},on:{"visibility":function($event){_vm.showDetails = $event},"close":_vm.closeDetailsDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }