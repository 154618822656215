<template>
    <Content
        :title="$t('navigation.tracks')"
    >
        <template v-slot:filters>
            <FiltersBar ref="filtersBar" @search="search = $event" class="flex-grow-0"></FiltersBar>
        </template>
        <template v-slot:main>
            <TracksTable :loader="loader"
                         @fetch="fetchTracks"
                         @sort="sortingColumn = $event"
                         @change-page="changePage($event)"
                         @change-amount="changeAmount($event)"
            ></TracksTable>
        </template>
    </Content>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { FILTERS_SEARCHING, FILTERS_V2 } from '@/constants/filtersModelNames';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import filtering from '@/mixins/filtering';
import TracksTable from '@/components/cyclicRevisions/TracksTable';
import debounce from 'lodash.debounce';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomersV2';
import debouncedSites from '@/mixins/debounced/debouncedSitesV2';
import debouncedLocations from '@/mixins/debounced/debouncedLocationsV2';
import paginationSettings from '@/mixins/paginationSettings';
import { ICONS } from '@/constants/icons';
import Content from '@/components/templates/Content.vue';

export default {
    name: 'Tracks',
    components: { Content, FiltersBar, TracksTable },
    mixins: [
        filtering,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        paginationSettings
    ],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        debouncedFetchTracks: null,
        loader: false,
        sortingColumn: null,
        ICONS
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS_V2.CLIENT,
                    label: this.$t('labels.customers'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.LOCATION,
                    label: this.$t('labels.sites'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE,
                    referralCallback: this.referralSiteCallback,
                    lazy: this.getSitesLazyLoading,
                    searchCallback: this.debouncedGetSites,
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.POINT,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        }

    },
    watch: {
        sortingColumn: {
            handler () {
                // this.fetchEZTReports();
            },
            deep: true
        },
        search: {
            handler () {
                this.fetchTracks();
            },
            deep: true
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (!this.filters[f]) return;

                if (Array.isArray(this.filters[f]) && this.filters[f].length > 0) {
                    params[f] = this.filters[f].join(',');
                } else {
                    params[f] = this.filters[f];
                }
            });
            return params;
        },
        fetchTracks () {
            this.loader = true;
            const params = {
                limit: this.pagination.amount,
                ...this.generateFilters()
            };
            //
            if (this.pagination.page === 1) {
                params.offset = 0;
            } else {
                params.offset = (this.pagination.page - 1) * this.pagination.amount;
            }

            if (this.search) {
                params.search = this.search;
            }
            return this.$store.dispatch('cyclicRevisions/getTracks', {
                params: params
            }).finally(() => {
                this.loader = false;
            });
        },
        changePage (event) {
            this.pagination.page = event;
            // this.fetchTracks();
        },
        changeAmount (event) {
            this.pagination.amount = event;
            // this.fetchTracks();
        }
    },
    created () {
        this.debouncedFetchTracks = debounce(this.fetchTracks, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchTracks);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
            vm.$store.dispatch('locations/getLocations')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS_V2.POINT).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            vm.$store.dispatch('customers/getCustomers')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS_V2.CLIENT).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            vm.fetchTracks();
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
